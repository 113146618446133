@import 'dependencies';

body {
  font-size: 11px;
}

.l-region--meta,
.l-region--featured-container,
.l-region--navigation,
.l-search,
.l-region--help,
.l-main--content_footer,
.sticky,
.l-footer,
.tabs--primary {
  display: none;
}

.l-header * {
  position: static !important;
  height: auto !important;
  top: auto !important;
}

.l-content .pane-title {
  margin: 1em 0 0;
  padding: 0;
}

.scrolling--container {
  position: static !important;
}

.contextual-links-wrapper,
.gallery-thumbs,
.group-recipe-related-products {
  display: none !important;
}

.node--full img {
  float: none;
}

.node--images {
  @include span-columns(5);
}

.recipe__content {
  @include span-columns(10);
  @include omega;
  padding-left: $gutter;
}

.region--comments,
.region--push {
  display: none;
}

.optionset-galleryformatter-navigation {
  display: none;
}

.group-product-allergy-ingredients .group-recipe-allergy,
.group-recipe-allergy-complexity .group-recipe-allergy,
.group-product-allergy-ingredients .group-recipe-complexity,
.group-recipe-allergy-complexity .group-recipe-complexity {
  width: 32%;
}

#l10n-client,
.field--print-recipe,
  // .panel-overlay,
.element-invisible,
.node--recipe--full .group-right > span.field-label,
.field--recipe-number-wrapper,
div.horizontal-tabs ul.horizontal-tabs-list {
  display: none;
}

table {
  display: table;

}
fieldset .fieldset-wrapper, .horizontal-tabs fieldset.horizontal-tabs-pane .fieldset-wrapper, .ui.accordion:not(.styled)
fieldset .title ~ .content:not(.ui):last-child, .ui.accordion:not(.styled)
fieldset .title ~ .content:not(.ui) {
  display: block !important;
  overflow: visible !important;
  padding-top: 0;
  padding-right: 0;
  padding-left: 0;
}

.ui.accordion fieldset .title:not(.ui) {
  padding-left: 0;
}

.div.horizontal-tabs fieldset.horizontal-tabs-pane,
.tabs fieldset.horizontal-tabs-pane {
  display: block !important;
}

fieldset, .horizontal-tabs fieldset.horizontal-tabs-pane {
  margin-bottom: 0;
}

.ui.accordion legend.title:after, .ui.accordion .views-exposed-widget > .title:after, .ui.accordion .item.collapsed > .title:after, .ui.accordion .item.expanded > .title:after {
  display: none;
  padding: 0;
}

fieldset.group-recipe-ingredients.field-group-htab.horizontal-tabs-pane {
  margin-top: 0;
}

.horizontal-tabs fieldset.horizontal-tabs-pane {
  border: none;
  padding: 0;
}

fieldset.horizontal-tabs-pane > legend {
  border: none;
  box-shadow: none;
  display: block;
  padding-left: 0;
  font-family: sans-serif;
}

.horizontal-tabs .horizontal-tab-hidden {
  position: static !important;
}

.field--recipe-description {
  border-top: none;
  padding-top: 0;
}
.field--recipe-body {
  padding: 0 !important;
}

.field--rating,
.social__title,
.socials {
  display: none;
}

table {
  border: solid 1px $light !important;
  max-width: 100%;
  width: 100%;
  thead th {
    border-right: solid 1px $light;
    padding: 0.05em 0.5em;
    background-color: #f0f4cc;
  }
  tbody tr td {
    padding: 0.05em 0.5em;
  }
}

.group-recipe-allergy-complexity .group-recipe-complexity .field-label,
.group-recipe-allergy-complexity .group-recipe-allergy .field-label {
  font-size: 11px !important;
}

.node--recipe--full .group-right .group-recipe-horizontal-tabs .horizontal-tabs-panes fieldset legend {
  padding-top: 0;
}
